<template>
  <div class="col-md-8 mx-auto">
    <ModalForm :visible="show" @close="show = false" :message="modalMsg" />
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Copia de seguridad</h1>
        </div>
        <br>
        <form v-on:submit.prevent="downloadBackup(allfiles)">
          <div class="form-row">
            <div class="form-group col-md-12 text-center">
              <div class="text-center"><img src="/images/backup.png"></div>
              <br>
              <div class="text-center"><p>Descargar una copia de los documentos registrados en el sistema.</p></div>
            </div>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row" align="center">
            <div class="col-6">
              <button type="submit" class="btn btn-primary" v-bind:disabled="show">Descargar</button>
            </div>
            <div class="col-6">
              <button type="text" class="btn btn-secondary" v-on:click="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';
  import ModalForm from '../modal/ModalForm.vue';

  export default {
    components: {
      ModalForm
    },
    data(){
      return{
        item: {},
        message: '',
        debug: '',
        allfiles: {},
        show: false,
        ModalForm: ''
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {

    },
    mounted: function(){

    },
    methods: {
      downloadBackup(allfiles){
        const downloadProgress = (allfiles) => (progressEvent) => {
          allfiles.downloadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
          //this.$set(adjuntos, x, adjuntos[x]);
        }

        this.ModalForm = 'Por favor espere mientras se realiza la copia de seguridad..';
        this.show = true;
        allfiles.downloadPercentage = 0;

        var zip = 'backup.zip';


        this.axios({
          url: '/api/backup-all',
          method: 'POST',
          data: { 'cmp_id': this.$store.state.company, 'usu_cdgo': this.$store.state.user, 'temp': false, 'ts': this.timestamp, 'zip': zip },
          responseType: 'blob',
          onDownloadProgress: downloadProgress(allfiles).bind(this)            
        })
        //.then(wait(5000))
        .then((response) => {
            this.show = false;
            //this.$set(adjuntos, x, adjuntos[x]);
                      
            const url = window.URL.createObjectURL(new File([response.data], zip, {type: 'application/octet-stream'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', zip);
            //link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();          
        })
        .catch(err => {
            this.show = false;
            //this.$set(adjuntos, x, adjuntos[x]);
        });
      },    
      cancel(){
        this.$router.replace({ name: 'Home' });
      }

    } //END METHODS
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>